import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import styled from "styled-components"
import Layout from "../components/layout"
import { LanguageContext } from "../context/languageContextProvider"

function Gallery() {
  const [language, setLanguage] = React.useContext(LanguageContext)

  return (
    <Layout>
      <Seo title="Gallery" />
      <GalleryHeader />
      <GalleryMain>
        <h1>{language === "EN" ? "Our Gallery" : "我们的画廊"}</h1>
        <GallerySelection>
          <GalleryChoice to="/gallery/couples">
            <img
              src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086873/couples/couple-9v_x7wdbj.jpg"
              alt=""
            />
            <div>
              <p>{language === "EN" ? "Couples" : "新婚夫妇"}</p>
            </div>
          </GalleryChoice>
          <GalleryChoice to="/gallery/decorations">
            <img
              src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596088149/sets/set-5_sufomn.jpg"
              alt=""
            />

            <div>
              <p>{language === "EN" ? "Decorations" : "装饰"}</p>
            </div>
          </GalleryChoice>
          <GalleryChoice to="/gallery/flowers">
            <img
              src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596090145/flowers/flower-5_tzgmfo.jpg"
              alt=""
            />
            <div>
              <p>{language === "EN" ? "Flowers" : "花卉"}</p>
            </div>
          </GalleryChoice>
          <GalleryChoice to="/gallery/wardrobe">
            <img
              src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512132/wardrobe/wardrobe-12_bglioj.jpg"
              alt=""
            />
            <div>
              <p>{language === "EN" ? "Wardrobe" : "立柜"}</p>
            </div>
          </GalleryChoice>
        </GallerySelection>
      </GalleryMain>
    </Layout>
  )
}

export default Gallery

const GalleryHeader = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(147, 181, 179, 0.1)
    ),
    url("https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1596086875/couples/couple-12v_gu9jvl.jpg");
  width: 100%;
  height: 35vh;
  background-size: cover;
  background-position: center;
  margin-bottom: 3rem;

  @media screen and (max-width: 768px) {
    height: 25vh;
  }
`

const GalleryMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;

  h1 {
    text-decoration: underline;
    margin-bottom: 3rem;
    text-align: center;
    font-family: "Noto Serif Display", serif;
  }
`

const GallerySelection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: 80vw;
  max-width: 1200px;
  margin: 3rem auto 6rem auto;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const GalleryChoice = styled(Link)`
  height: 500px;
  width: 250px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 0 0.5rem;

  @media screen and (max-width: 768px) {
    height: 40vw;
    width: 100%;
    margin-bottom: 1rem;
  }

  &:hover {
    transform: translate(-5px, -5px);

    div {
      background: rgba(0, 0, 0, 0.1);
    }

    img {
      transform: scale(1.1);
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    transition: all 0.2s ease;

    @media screen and (max-width: 768px) {
      height: 100%;
      width: 100%;
    }
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
  }

  p {
    color: white;
    font-size: 1.5rem;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    margin: 0;

    @media screen and (max-width: 1200px) {
      font-size: 1.3rem;
    }
  }
`
